.services {
    display: flex;
    width: 100%;
    overflow-x: auto;
    position: absolute;
    bottom: 0px;
}

.services::-webkit-scrollbar {
    display: none;
}

.services_Content {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.services_Card {
    background-color: rgba(17, 17, 17, 0.279);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 1rem;
    padding: 1rem;
    width: 300px;
}

.services_Header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.services_Header > h1 {
    font-weight: 400;
    font-size: 1.5rem;
}

.services_Card > p {
    font-size: 0.8rem;
    font-weight: 300;
}