.faq_Content {
    background-color: #121212;
    color: #FEFEFE;
    padding: 2rem;
}

.faq_Content > h2 {
    text-align: center;
    font-weight: 300;
}

.faq_Questions {
    margin-top: 3rem;
}

.faq_Question {
    padding-bottom: 1rem; 
}

.faq_QuestionHeader {
    display: flex;
    border-bottom: 1px solid #FEFEFE;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    cursor: pointer;
}

.faq_QuestionHeader > h2 {
    padding-right: 5rem;
    font-weight: 300;
    width: 90%;
}

.faq_QuestionHeader > svg {
    width: 5%;
}

.faq_Answer {
    background-color: #FEFEFE;
    color: #202020;
    padding: 1rem;
    text-align: left;
}

.underline_Black {
    text-decoration: underline;
    cursor: pointer;
}
