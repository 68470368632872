/* Portfolio.css */
.portfolio-container {
    display: grid;
    background-color: #111;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px; /* Space between grid items */
    padding: 16px; /* Padding around the grid */
  }
  
  .portfolio-item {
    background-color: #272727; /* Background color for each item */
    border-radius: 8px; /* Rounded corners */
    color: #FEFEFE;
    font-size: 1rem;
    font-weight: 200;
    overflow: hidden; /* Prevent content overflow */
    text-align: center; /* Center the text */
    transition: transform 0.3s; /* Animation for hover effect */
  }
  
  .portfolio-item:hover {
    transform: scale(1.05); /* Scale effect on hover */
    cursor: pointer;
  }
  
  .portfolio-item img {
    width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
  }
  
  .portfolio-item h3 {
    margin: 8px 0; /* Margin around the title */
    font-size: 1rem; /* Font size for the title */
    font-weight: 300;
  }
  