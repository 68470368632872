.banner {
    background-color: #FEFEFE;
    color: #111;
}

.banner_Content {
    display: flex;
    padding: 2rem;
    background-color: #111;
}

.banner_Left {
    width: 50%;
    background-color: #FEFEFE;
    padding: 2rem;
    height: 80vh;
}

.banner_Copy {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.banner_Text {
    display: flex;
    flex-direction: column;
}

.banner_Text > h1 {
    font-weight: 300;
    font-size: 2.5rem;
    max-width: 70%;
}

.banner_Info {
    display: flex;
    flex-direction: column;
}

.banner_Right {
    width: 50%;
    padding: 2rem;
    background-color: #FEFEFE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner_Image {
    display: flex;
    height: 100%;
    width: 100%;
    background-image: url('/public/images/banner_background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 600px) {
    .banner_Right {
        display: none;
    }

    .banner_Left {
        width: 100%;
    }
}
