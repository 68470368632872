.clients {
    background-color: #111;
    color: #FEFEFE;
}

.clients_Content {
    padding: 1rem;
    gap: 3rem;
    display: flex;
    overflow-x: auto;
    justify-content: space-between;
}

.client > img{
    height: 10rem;

    filter: grayscale(1);
}

.client > img:hover{
    filter: grayscale(0);
    cursor: pointer;
}

.clients_Content::-webkit-scrollbar {
    display: none;
}



.clients_Content {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}
