.navbar {
    background-color: rgba(17, 17, 17, 0.279);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    position: fixed;
    width: 100%;
    flex-direction: row;
    color: #FEFEFE;
    font-weight: 300;
    width: 100%;
    z-index: 100;
}

.navbar_Content {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.navbar_Logo {
    display: flex;
    height: 3rem;
    width: 3rem;
}

.navbar_Items {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .navbar_Items {
        display: none;
    }
}